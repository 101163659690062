export const STATE_NAME = 'contactAdvertiser';

export enum State {
  INITIAL = 'initial',
  LOADING = 'loading',
  ERROR = 'error',
  LOADED = 'loaded',
  REDIRECT = 'redirect',
}

export const CONVERSATION_START_MESSAGE_STORAGE_KEY = 'conversationStart';
